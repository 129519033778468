import React, { useState, useEffect, useRef } from "react";
import { sendBlobToBackend, getTokenOrRefresh } from "../utils";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";
import TypingBubble from "./TypingBubble";

const ChatInput = ({ url, azureToken, onComplete, setAzureToken, setError,refreshCheckList,setAudioBlob,showInputElement }) => {
  const [blob, setBlob] = useState();
  const [recognizer, setRecognizer] = useState();
  const [transcript, setTranscript] = useState([]);
  const transcriptRef = useRef(transcript);
  const recorder = useAudioRecorder();

  const [showStopBtn, setShowStopBtn] = useState(false);
  const [userPressedStop, setUserPressedStop] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [azRecordingFailed, setAzRecordingFailed] = useState(false);
  const [speechEndDetected, setSpeechEndDetected] = useState(false);
  // const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRecognizerReady, setIsRecognizerReady] = useState(false);

  useEffect(() => {
    const initializeRecognizer = async () => {
      try {
        console.log("Starting recognizer initialization...");
        const tokenObj = await getTokenOrRefresh();
        if (tokenObj.error) {
          throw new Error("Failed to refresh token");
        }
        setAzureToken(tokenObj);
        const currRecognizer = azureRecorderSetup(tokenObj);
        setRecognizer(currRecognizer);

        currRecognizer.recognized = (s, e) => processRecognizedTranscript(e);
        currRecognizer.speechStartDetected = () => setSpeechEndDetected(false);
        currRecognizer.speechEndDetected = () => setSpeechEndDetected(true);
        currRecognizer.recognizing = () => console.log("speech recognizing");

        currRecognizer.canceled = (s, e) => {
          if (e.errorCode === sdk.CancellationErrorCode.AuthenticationFailure) {
            console.error("Authentication error, refreshing token...");
            refreshRecognizerToken();
          } else {
            console.error("Recognizer canceled: ", e);
            setAzRecordingFailed(true);
          }
        };
        
        console.log("Recognizer initialization complete");
        setIsRecognizerReady(true);
      } catch (error) {
        console.error("Error initializing recognizer: ", error);
        setError("Failed to initialize speech recognition. Please try again later.");
        setAzRecordingFailed(true);
      }
    };

    if (!recognizer && !azRecordingFailed) {
      console.log("initializing recognizer");
      initializeRecognizer();
    }
  }, [recognizer, setAzureToken,azRecordingFailed]);

  useEffect(() => {
    console.log("recognizer", recognizer);
    return () => {
      console.log("recognizer unmounted");
    } 
  }, [recognizer]);
  const azureRecorderSetup = (tokenObj) => {
    if (tokenObj.error) {
      throw new Error("Failed in setting up this chat!");
    }
    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    return new sdk.SpeechRecognizer(speechConfig, audioConfig);
  };

  const processRecognizedTranscript = (event) => {
    if (event.result.reason === sdk.ResultReason.RecognizedSpeech) {
      const newTranscript = [...transcriptRef.current, event.result.text];
      setTranscript(newTranscript);
      transcriptRef.current = newTranscript;
    }
  };

  const refreshRecognizerToken = async () => {
    try {
      const tokenObj = await getTokenOrRefresh();
      if (tokenObj.error) {
        throw new Error("Failed to refresh token");
      }
      setAzureToken(tokenObj);
      const currRecognizer = azureRecorderSetup(tokenObj);
      setRecognizer(currRecognizer);
      setError(null);
    } catch (error) {
      console.error("Error refreshing token: ", error);
      
      setError("Failed to refresh token. Please try again later.");
      setAzRecordingFailed(true);
    }
  };

  useEffect(() => {
    if (!recorder.isRecording && recorder.recordingBlob) {
      setBlob(recorder.recordingBlob);
      
    }
  }, [recorder.recordingBlob, recorder.isRecording]);

  useEffect(() => {
    const sendData = async () => {
      if (
        !isProcessing &&
        blob &&
        transcriptRef.current.length > 0 &&
        userPressedStop &&
        speechEndDetected
      ) {
        setIsProcessing(true);
        setIsTyping(true);
        try {
          const bot_response = await sendBlobToBackend(url, blob, transcript.join(". "));
          setIsTyping(false);
          setSpeechEndDetected(false);
          setTranscript([]);
          setUserPressedStop(false);
          onComplete(transcript, bot_response);
          setAudioBlob(recorder.recordingBlob);
          // refreshCheckList();
        } catch (error) {
          console.error("Error sending data to backend:", error);
          setError("Error sending data to backend. Please try again later.");
          setAzRecordingFailed(true);
        } finally {
          transcriptRef.current = [];
          setIsProcessing(false);
        }
      }
    };

    sendData();
  }, [blob, transcript, userPressedStop, speechEndDetected, url, onComplete, isProcessing]);

  function startRecording() {
    if (!isRecognizerReady) {
      console.log("Recognizer not ready yet");
      return;
    }
    
    console.log("startRecording");
    setTranscript([]);
    setShowStopBtn(true);
    setUserPressedStop(false);
    recorder.startRecording();
    recognizer?.startContinuousRecognitionAsync();
  }

  function stopRecording() {
    console.log("stopRecording");
    recorder.stopRecording();
    recognizer?.stopContinuousRecognitionAsync();
    setUserPressedStop(true);
    setShowStopBtn(false);
  }

  function hideError() {
    setAzRecordingFailed(false);
  }

  const handleKeyDown = (e) => {
   if (document.activeElement.id === "summary-box") {
    return;
   }
    if (e.code === 'Space' && !recorder.isRecording) {
      e.preventDefault(); // Prevent scrolling
      startRecording();
    }
  };

  const handleKeyUp = (e) => {
    if (document.activeElement.id === "summary-box") {
      return;
    }
    if (e.code === 'Space' && recorder.isRecording) {
      e.preventDefault(); // Prevent scrolling
      stopRecording();
    }
  };

  useEffect(() => {
    // Attach event listeners to the document
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      // Cleanup event listeners
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [recorder.isRecording, isRecognizerReady]);

  return (
    <div>
      {/* {error && <div className="text-red-500">{error}</div>} */}
      {azRecordingFailed && (
        <div className="z-50">
          <div id="error-message" className="text-red-500 text-sm font">
            Refresh this page to continue from where you left off
            <button
              className="text-red-500 hover:text-red-700 focus:outline-none"
              onClick={hideError}
            >
              <svg width="20" height="20" viewBox="0 0 40 40">
                <path
                  d="M 10,10 L 20,20 M 20,10 L 10,20"
                  stroke="black"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {showInputElement && <div className="flex space-x-4 justify-center">
        {isTyping && <TypingBubble />}
        <div>
          {!isTyping && !showStopBtn && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={startRecording}
            >
              Start recording
            </button>
          )}
          {!isTyping && showStopBtn && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={stopRecording}
            >
              Stop recording
            </button>
          )}
        </div>
      </div>}
      {recorder.isRecording && (
        <div className="flex space-x-4 justify-center">
          <LiveAudioVisualizer
            mediaRecorder={recorder.mediaRecorder}
            width={200}
            height={75}
            barWidth={0.5}
            barColor="#051226"
          />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
