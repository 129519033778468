import React, { useState } from "react";
import "../assets/styles/ChatStatus.css"; // Assuming the CSS is in Chat.css
import ChatClose from "./ChatClose";
import { SimResponse, ChatResponse } from "../models/Simulation";
import { Instructions, SIM_CONFIGS, Stage } from "../utils";

interface ChatStatusProps {
  simulation: SimResponse;
  chat: ChatResponse;
  personaImage: any;
  onClose: () => void;
}


function getInstructions(simType: keyof typeof SIM_CONFIGS): Instructions {
  console.log("simType", simType);
  if (!SIM_CONFIGS[simType.toUpperCase() as keyof typeof SIM_CONFIGS]) {
    console.log("No config found for simType", simType);
    return SIM_CONFIGS.DEFAULT.instructions;
  }
  const config = SIM_CONFIGS[simType.toUpperCase() as keyof typeof SIM_CONFIGS];

  console.log("config", config);
  return config.instructions;
}

function ChatStatus({ simulation, chat, personaImage, onClose }: ChatStatusProps): JSX.Element {
  console.log("simulation", simulation);
  const [isBardPopoverOpen, setIsBardPopoverOpen] = useState(true);
  const toggleBardPopover = () => setIsBardPopoverOpen(!isBardPopoverOpen);
  function bardInfo(simType: keyof typeof SIM_CONFIGS): JSX.Element {
    const instructions = getInstructions(simType);

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="w-full max-w-2xl max-h-[90vh] overflow-y-auto bg-white rounded-lg shadow-xl">
          <div className="sticky top-0 bg-white z-10 border-b p-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold text-gray-800">
                About the Role Play
              </h2>
              <button
                onClick={toggleBardPopover}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="p-4 space-y-4">
            {personaImage && (
              <div className="flex justify-center mb-4">
                <img
                  src={personaImage}
                  alt="Persona"
                  className="w-32 h-32 rounded-full object-cover border-2 border-indigo-200"
                />
              </div>
            )}

            <p className="text-gray-700">{instructions.intro}</p>
            <p className="italic text-gray-600 border-l-4 border-gray-300 pl-4">
              {simulation.personaDetails.split("\n").map((line: string, index: number) => (
                <p key={index}>{line}</p>
              ))}
            </p>
            <div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Stages and Goals
              </h3>
              <div className="space-y-2">
                {instructions.stages.map((stage: Stage, index: number) => (
                  <div
                    key={index}
                    className="bg-indigo-50 border border-indigo-200 rounded-lg p-3 mb-2"
                  >
                    <h4 className="text-sm font-semibold text-indigo-700 mb-1">
                      {stage.title}
                    </h4>
                    <ul className="list-disc pl-5 space-y-0.5 text-xs text-gray-700">
                      {stage.subgoals.map(
                        (subgoal: String, subIndex: number) => (
                          <li>{subgoal}</li>
                        )
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-gray-100 w-full h-full overflow-hidden rounded-lg shadow-md">
      <div className="flex flex-row justify-between bg-white border-[#9bb1ff] border-2 p-4 rounded-lg text-white">
        <h2 className="text-xl font-bold text-black">
          Instructions and Context
          <button
            type="button"
            className="text-gray-400 hover:text-blue-500 focus:outline-none ml-2"
            onClick={toggleBardPopover}
          >
            <span className="text-gray hover:text-blue-500 focus:outline-none">
              (i)
            </span>
          </button>
        </h2>
        {/* <button className={` ${chat.status === 'ACTIVE' ? "text-white bg-green-500 hover:bg-green-700 focus:outline-none px-2 py-1 rounded-full"
        : "text-white bg-red-500 hover:bg-red-700 focus:outline-none px-2 py-1 rounded-full" }`}>
          <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-6h-2v2h2V12z"
              clipRule="evenodd"
            />
          </svg>
        </button> */}
        <ChatClose chat={chat} onClose={onClose} />
        {isBardPopoverOpen && bardInfo(simulation.simType as keyof typeof SIM_CONFIGS)}
      </div>
    </div>
  );
}

export default ChatStatus;
