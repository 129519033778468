import React, { useEffect, useRef } from "react";
import { FEEDBACK_KEYS } from "../utils";
import FeedbackSuggestion from "./FeedbackSuggestion";

// Define type for feedback keys
export type FeedbackKeyType = keyof typeof FEEDBACK_KEYS;

interface ChatHistoryProps {
  messages: Message[];
  personaImage: string;
  feedbackMessages: FeedbackMessage[];
  showFeedback: boolean;
}

interface Message {
  sender: string;
  content: string;
  time: Date;
}

interface FeedbackMessage {
  feedback: {
    [key in FeedbackKeyType]?: {
      score?: number;
      suggestion1?: string;
      suggestion2?: string;
    };
  };
}

const ChatHistory = ({ messages, personaImage, feedbackMessages, showFeedback }: ChatHistoryProps) => {
  useEffect(() => {
    const objDiv = document.getElementById("messages");
    console.log("scrolling to bottom ", objDiv);
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  });

  return (
    <div className="grid space-y-4 m-2 flex-grow mt-8 ml-0">
      {messages.length === 0 ? (
        <>
          <div className="m-auto p-4 rounded-lg bg-gray-200 ">
            <p className="text-sm text-center">
              Start recording to initiate the conversation.
            </p>
          </div>
        </>
      ) : (
        messages.map((message, index) => (
          <div key={index} className="flex flex-col gap-2">
            <div className={`flex ${message.sender === "U"
              ? "flex-row-reverse max-w-[70%] self-end "
              : "justify-self-start max-w-[70%] "
              }`} >
              {message.sender !== "U" && personaImage && <div className="min-w-[60px] flex flex-col justify-center p-1">
                {message.sender !== "U" && personaImage && (
                  <img src={personaImage} alt="Persona" className="w-12 h-12 rounded-full object-cover border-2 border-indigo-200" />
                )}
              </div>}
              <div
                key={index}
                className={`relative p-4 rounded-lg shadow-sm font-medium ${message.sender === "U"
                  ? "justify-self-end bg-[#e4e7e7] message-user "
                  : personaImage
                    ? "justify-self-start bg-[#c6cdf3] ml-[2px] text-[#686868] message-bot-with-image"
                    : "justify-self-start bg-[#c6cdf3] ml-[2px] text-[#686868] message-bot"
                  }`}
              >

                {/* {console.log(message)} */}
                <p className="text-sm p-1">{message.content}</p>
                {/* <div className="flex justify-end">
              <p className="text-xs">{message.time.toLocaleDateString()}</p>
            </div> */}
              </div>

            </div>
            {showFeedback && feedbackMessages[index] && feedbackMessages[index].feedback &&
              Object.keys(feedbackMessages[index].feedback).map((key) => {
                const feedback = feedbackMessages[index].feedback[key as FeedbackKeyType];
                let showAlternateSuggestion = false;
                if (feedback?.score && feedback.score >= 1) {
                  showAlternateSuggestion = true;
                } else if (key === 'voice_qa' && feedback !== null) {
                  showAlternateSuggestion = true;
                }
                return showAlternateSuggestion && (
                  <FeedbackSuggestion
                    key={`${index}-${key}`}
                    feedbackKey={key}
                    feedback={{ score: feedback?.score, suggestion1: feedback?.suggestion1 }}
                  />
                );
              })}
          </div>
        ))
      )}
    </div>
  );
};

export default ChatHistory;
