import { Button, Typography } from "@mui/material";
import React from "react";


interface SummaryBoxProps {
    onChange: (summary: string) => void;
    onSubmit: () => void;
    onClose: () => void;
    summary: string;
    visible: boolean;
    onToggle: () => void;
}

const SummaryBox = ({ onChange, onSubmit, onClose, summary, visible, onToggle }: SummaryBoxProps) => {
    return (
        <div className="relative h-full">
            {visible && (
                <div className="bg-white h-full rounded-lg shadow-lg border border-gray-200 flex flex-col overflow-hidden">
                    <div className="px-6 py-4 border-b border-gray-200">
                        <div className="text-lg font-medium text-gray-700">Encounter Note</div>
                    </div>
                    <div className="p-6 flex-grow">
                        <textarea
                            id="summary-box"
                            className="w-full h-full min-h-[200px] resize-none rounded-md p-4
                                bg-gray-50 border border-gray-200
                                focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:bg-white
                                transition-all duration-200 ease-in-out
                                text-gray-700 placeholder-gray-400
                                outline-none"
                            onChange={(e) => onChange(e.target.value)}
                            value={summary}
                            placeholder="Enter your encounter notes here..."
                        />
                    </div>
                </div>
            )}
            <div
                id="summary-box-arrow"
                className={`absolute top-1/2 w-[10px] h-[100px] 
                    ${visible ? 'right-0 rounded-l-lg' : '-left-4 rounded-r-lg'}
                    bg-gray-200 hover:bg-gray-300 
                    -translate-y-1/2
                    cursor-pointer transition-all duration-200
                    flex items-center justify-center`}
                onClick={onToggle}
            >
                <div className={`rotate-90 text-gray-400 font-bold text-m whitespace-nowrap ${visible ? 'pb-2' : 'pt-2'}`}>
                    {visible ? '⌄' : '⌃'}
                </div>
            </div>
        </div>
    );
}

export default SummaryBox;