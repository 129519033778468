import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchAuthenticatedUrl } from "../utils";
import Checklist from "../components/Checklist";
import "../assets/styles/Simulation.css";
import ChatDetails from "../components/ChatDetails";
import { mapJsonToSimResponse } from "../models/Simulation";
import { Dialog } from "@mui/material";
import YouTube from "react-youtube";
import HelpIconModal from "../components/HelpIconModal";
import SummaryBox from "../components/SummaryBox";
const SimulationChat = () => {
  const { sim_id, chat_id } = useParams();
  const [simulation, setSimulation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isChatInitialized, setChatInitialized] = useState(false);
  const [isAssessment, setIsAssessment] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [recheckGoalStatus, setRecheckGoalStatus] = useState(false);
  const [refreshUrl, setRefreshUrl] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackActive, setFeedbackActive] = useState(false);
  const [summary, setSummary] = useState("");
  const [showSummaryBox, setShowSummaryBox] = useState(false);
  const [summaryBoxVisible, setSummaryBoxVisible] = useState(true);
  const refreshCheckList = () => {
    setRecheckGoalStatus((prev) => !prev);
  }
  useEffect(() => {
    console.log("in useffect with sim_id and chat_id");
    let url = "";
    if (sim_id && chat_id) {
      url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}/${chat_id}`;
      setRefreshUrl(url);
    } else if (sim_id && !chat_id) {
      url = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${sim_id}`;
    }

    fetchAuthenticatedUrl(url).then((resp) => {
      console.log(resp);
      if (resp.data) {
        setSimulation(mapJsonToSimResponse(resp.data));
        //TODO handle this
        if (resp.data.is_assessment) {
          setIsAssessment(true);
        }
        if (resp.data.summary_required) {
          setShowSummaryBox(true);
        }
        if (resp.data.chat_response[0].status === "CLOSED") {
          navigate(`/sim/${sim_id}/${chat_id}/transcript`);
        } else if (resp.data.status == "ACTIVE") {
          setChatInitialized(true);
        }
      } else {
        setError(resp.error + " " + "check backend service!");
      }
      setIsLoading(false);
    });
  }, [sim_id, chat_id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!simulation) {
    return <div>No simulation found</div>;
  }

  const handleFeedbackClick = () => {
    setShowFeedback((prev) => !prev);
    if (feedbackActive) {
      setFeedbackActive(false);
    }
  }

  const handleSummarySubmit = () => {
    console.log("summary submitted", summary);
  }

  const handleSummaryClose = () => {
    setSummary("");
    setSummaryBoxVisible(false);
  }

  const toggleSummaryBox = () => {
    setSummaryBoxVisible(prev => !prev);
  };

  return (
    <div className={`flex flex-col ${isAssessment ? "w-[85%] mx-auto" : ""}`}>
      
      <div className="flex justify-between">
        <div>
          <button
            className="border rounded-lg bg-blue-500 p-2 text-white w-[200px] mb-2"
            onClick={() => setVideoDialogOpen(true)}
          >
            Watch Instruction Video
          </button>
          
        </div>
        <div className="flex gap-2">
          <HelpIconModal />
        </div>
      </div>
      <div className="flex flex-row gap-4 relative">
        {!isAssessment && <div className="w-1/4 bg-white p-4 rounded-l-lg shadow-md flex flex-col justify-between">
          <div className="mb-4">
            <div className="p-1 rounded-lg">
              <h2 className="text-lg font-bold text-green-700">Objectives</h2>
              <Checklist
                refreshUrl={refreshUrl}
                stageHistory={simulation.chatResponse[0]?.stageHistory}
                currStage={simulation.chatResponse[0].stage}
                goals={simulation.goals}
                recheckGoalStatus={recheckGoalStatus}
              />
            </div>
            { simulation.config.realtimeFeedbackConfig.length>0 &&
           <div className="flex flex-row mt-4">
            <div className="flex flex-col">
              <h2 className={`text-lg font-bold cursor-pointer ${feedbackActive&&!showFeedback ? "text-yellow-700" : ""}`} onClick={handleFeedbackClick}>{showFeedback ? "Hide feedback" : "Feedback"}</h2>
              
            </div>
           </div>}
          </div>
            <p className="text-sm text-gray-600 mt-1">
            Note: If the role play gets stuck, just refresh the page to continue from where you left off.
          </p>
        </div>}
        <div className={`${!isAssessment ? "w-2/4" : "w-2/3 rounded-l-lg"} bg-white p-4  shadow-md flex-1 flex flex-col`}>
          <div className="bg-white p-4 rounded-lg flex-1">
            <div className="h-full overflow-y-auto">
              <ChatDetails
                showFeedback={showFeedback}
                setFeedbackActive={setFeedbackActive}
                refreshCheckList={refreshCheckList}
                simulation={simulation}
                chat={simulation.chatResponse[0]}
                personaId={simulation.personaId}
                summary={summary}
              />
            </div>
          </div>
        </div>
        {showSummaryBox  && (
          <div className={`${summaryBoxVisible ? (!isAssessment ? "w-1/4" : "w-1/3") : "w-0"}`}>
            <SummaryBox 
              summary={summary} 
              onChange={setSummary} 
              onSubmit={handleSummarySubmit} 
              onClose={handleSummaryClose}
              visible={summaryBoxVisible}
              onToggle={toggleSummaryBox}
            />
          </div>
        )}
      </div>
     {isAssessment && <div className="text-sm text-gray-600 mt-1 text-center">
      Note: If the role play gets stuck, just refresh the page to continue from where you left off.
      </div>}
      <Dialog
        open={videoDialogOpen}
        onClose={() => setVideoDialogOpen(false)}
        maxWidth="xl"
      >
        <YouTube
          videoId="-j9h2xFu6g0"
          opts={{
            height: "380",
            width: "730",
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </Dialog>
    </div>
  );
};

export default SimulationChat;
