import React, { useState } from "react";
import { Typography } from "@mui/material";
import EmblaCarousel from "./EmblaCarousel";
import "./embla.css";
import Step1 from "../../../assets/images/instruction_step1.png";
import Step2 from "../../../assets/images/instruction_step2.png";
import Step3 from "../../../assets/images/instruction_step3.png";
import Step4 from "../../../assets/images/instruction_step4.png";
import Step5 from "../../../assets/images/instruction_step5.png";
import Step6 from "../../../assets/images/instruction_step6.png";
import YouTube from "react-youtube";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SimInstruction = () => {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const navigate = useNavigate();
  const OPTIONS = {};
  const simId = localStorage.getItem("sim_id");
  const chatId = localStorage.getItem("chat_id");
  const SLIDES = [
    {
      id: 1,
      instructionText:
        "Initiate the conversation by clicking on the “start recording“ button, and giving the webpage permission to access your microphone",
      instructionImage: <img src={Step1} />,
    },
    {
      id: 2,
      instructionText:
        "When you are done speaking, click on the stop recording button. ",
      instructionImage: <img src={Step2} />,
    },
   
    {
      id: 3,
      instructionText:
        "Wait for the simulation to respond to you, and then respond back to continue the conversation by clicking on “Start Recording” again.",
      instructionImage: <img src={Step1} />,
    },
    {
      id: 4,
      instructionText:
        "If you want to review the case history and expectations from the role play you can click the “Instructions and Context” icon.",
      instructionImage: <img src={Step5} className="w-800" />,
    },
    {
      id: 5,
      instructionText:
        "Depending on what kind of role play you have been assigned you may or may not see goals on the left. If you do, know that the conversation is divided into stages, each with specific conversational goals that are actions that you need to complete, or information that you need to obtain from the patient. These are visible on the top left of the screen. In case you don’t see any goals on the left proceed as you would with a standard encounter.",
      instructionImage: <img src={Step4} className="w-80" />,
    },
    {
      id: 6,
      instructionText:
        'The platform automatically determines when you have completed a certain goal, and then turns green. When all the goals of a stage are complete, you progress to the next stage which has its own goals. The simulation will keep going till all the goals in all the stages are completed.',
    },
    {
      id: 7,
      instructionText:
        "You may also see a window called “Encounter note” open. If you do, you are expected to fill it with a post call encounter / progress / SOAP note based on the standard practice followed by your organization.",
      instructionImage: <img src={Step6} className="w-800" />,
    },
    {
      id: 8,
      instructionText:
        "Finish your session at any time by clicking on the “End Chat” button. Doing so will submit your role play for assessment.",
    },
  ];
  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full justify-between">
        <Typography variant="h4">Welcome </Typography>
        <button
          className="border rounded-lg bg-blue-500 p-2 text-white"
          onClick={() => setVideoDialogOpen(true)}
        >
          Watch Instruction Video
        </button>
      </div>
      <div className="mt-4 ">
        <Typography variant="h7" className="font-bold">
          Kindly go through below instructions before starting the simulation.
          Click on Start whenever you are ready.
        </Typography>
        <div className=" mt-8 rounded-lg flex items-center justify-center">
          <EmblaCarousel slides={SLIDES} options={OPTIONS} />
        </div>
      </div>
      <button
        className="border rounded-lg bg-blue-500 p-2 text-white w-[30%] mt-8"
        onClick={() => navigate(`/sim/${simId}/${chatId}`)}
      >
        Start
      </button>
      <Dialog
        open={videoDialogOpen}
        onClose={() => setVideoDialogOpen(false)}
        maxWidth="xl"
      >
        <YouTube
          videoId="-j9h2xFu6g0"
          opts={{
            height: "380",
            width: "730",
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </Dialog>
    </div>
  );
};

export default SimInstruction;
