import { FEEDBACK_KEYS } from "../utils";
import { FeedbackKeyType } from "./ChatHistory";
import React from "react";
interface FeedbackSuggestionProps {
    feedbackKey: string;
    feedback: {
        suggestion1?: string;
        score?: number;
    };
}

const FeedbackSuggestion = ({ feedbackKey, feedback }: FeedbackSuggestionProps) => {
    if (feedback.score && feedback.score >= 3) {
        return (
            <div className="bg-[#e4e7e7] rounded-md p-2 text-[#686868] text-center border-dashed border-2 border-gray-400">
                <div className="flex flex-col gap-2">
                    <div className="text-xs">Nice job on {FEEDBACK_KEYS[feedbackKey as FeedbackKeyType].toLowerCase()} with this statement.</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="bg-[#e4e7e7] rounded-md p-2 text-[#686868] text-left border-dashed border-2 border-gray-400">
                <div className="flex flex-col gap-2">
                    <div className="text-xs">Alternate suggestion</div>
                    <p className="text-[13px]">
                        <span className="font-bold mr-1">
                            {FEEDBACK_KEYS[feedbackKey as FeedbackKeyType]}:
                        </span>
                        {feedback?.suggestion1}
                    </p>
                </div>
            </div>
        );
    }

};



export default FeedbackSuggestion; 